exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-company-policy-js": () => import("./../../../src/pages/company-policy.js" /* webpackChunkName: "component---src-pages-company-policy-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-electricity-cabinet-manufacturing-js": () => import("./../../../src/pages/electricity/cabinet-manufacturing.js" /* webpackChunkName: "component---src-pages-electricity-cabinet-manufacturing-js" */),
  "component---src-pages-electricity-customised-installation-js": () => import("./../../../src/pages/electricity/customised-installation.js" /* webpackChunkName: "component---src-pages-electricity-customised-installation-js" */),
  "component---src-pages-electricity-electrical-efficiency-js": () => import("./../../../src/pages/electricity/electrical-efficiency.js" /* webpackChunkName: "component---src-pages-electricity-electrical-efficiency-js" */),
  "component---src-pages-electricity-engineering-js": () => import("./../../../src/pages/electricity/engineering.js" /* webpackChunkName: "component---src-pages-electricity-engineering-js" */),
  "component---src-pages-electricity-index-js": () => import("./../../../src/pages/electricity/index.js" /* webpackChunkName: "component---src-pages-electricity-index-js" */),
  "component---src-pages-electricity-maintenance-js": () => import("./../../../src/pages/electricity/maintenance.js" /* webpackChunkName: "component---src-pages-electricity-maintenance-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-legal-notice-js": () => import("./../../../src/pages/legal-notice.js" /* webpackChunkName: "component---src-pages-legal-notice-js" */),
  "component---src-pages-mechanics-js": () => import("./../../../src/pages/mechanics.js" /* webpackChunkName: "component---src-pages-mechanics-js" */),
  "component---src-pages-meivcore-group-js": () => import("./../../../src/pages/meivcore-group.js" /* webpackChunkName: "component---src-pages-meivcore-group-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-team-js": () => import("./../../../src/pages/team.js" /* webpackChunkName: "component---src-pages-team-js" */)
}

